import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';


const Home = () => {

    useEffect(() => {
        const carousel = document.querySelector(".testimonials-carousel");
        const prevBtn = document.querySelector(".prev-btn");
        const nextBtn = document.querySelector(".next-btn");

        let scrollAmount = 0;
        const scrollStep = carousel.offsetWidth / 2;

        prevBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: -scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.max(0, scrollAmount - scrollStep);
        });

        nextBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.min(
                carousel.scrollWidth - carousel.clientWidth,
                scrollAmount + scrollStep
            );
        });

        return () => {
            prevBtn.removeEventListener("click", () => { });
            nextBtn.removeEventListener("click", () => { });
        };
    }, []);
    return (
        <div className="home">
            {/* Seção Hero com Vídeo de Fundo */}
            <section className="hero">
                <video autoPlay loop muted playsInline className="background-video">
                    <source src="/home/background.mp4" type="video/mp4" />
                    Seu navegador não suporta vídeos.
                </video>
                <div className="hero-text">
                    <h1>
                    Simplificamos a <span className="highlight">tecnologia de </span> 
                    
                    </h1>
                    <h2>
                    <span className="highlight">pagamentos</span> em PDV para desenvolvedores e 
                    </h2>
                    <h2>
                    seus clientes com uma única integração.
                    </h2>
                </div>
            </section>

            {/* Seção Sobre a Embed */}
            <section className="about">
                <div className="about-content">
                    <p>CONHEÇA  A  EMBED</p>

                    <div className="about-content-text mt-6 text-[25px] md:text-[30px]">
                        <h2>Soluções agnósticas para <span style={{fontWeight:'bold', marginLeft:5}}>comprar, vender, pagar</span>  <img style={{marginLeft:10}} src="home/logo2.png" alt="Sobre a Embed" className="about-image" /> receber</h2>
                    </div>
                    <p  className= 'mt-[180px] md:mt-[120px] text-justify' style={{fontWeight:'inherit', marginBottom:'30px'}}>Reinventamos a forma como software houses, automações comerciais e
                        comerciantes operam e crescem seus negócios. Nosso modelo de negócios é
                        baseado na <span style={{fontWeight:'bolder'}}>remuneração ao longo da distribuição</span> calculando os repasses de
                        acordo com o consumo transacional, gerando valor para todos os elos:</p>
                    <img src="home/about2.png" alt="Sobre a Embed" className="about-image" />
                    <button className='rounded-md'><Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>Saiba mais sobre a Embed</Link></button>
                </div>
            </section>

            {/* Nova Seção - Conheça Nossas Soluções */}
            <section className="about-solutions">
                <div className="solutions-content">
                    <div className="solutions-text">
                        <p className="solutions-heading">Nossas Soluções</p>
                        <p style={{fontWeight:'inherit', fontSize:'28px', margin: '0'}}>Negócios operam e crescem</p>
                        <p style={{fontSize:'28px', margin: '0', marginBottom: '40px'}}><span style={{fontWeight:'bolder'}}>com uma única integração</span> <span style={{fontWeight:'inherit'}}>na Embed</span></p>
                        <div className='text-justify md:text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p style={{maxWidth:'1100px', lineHeight: '1.5', fontSize:'18px', marginBottom:'50px'}}>
                                Com APIs fáceis de integrar e dados conectados, potencializamos o crescimento do seu
                                negócio e dos negócios dos seus clientes através do nosso ecossistema. <span style={{fontWeight:'bolder'}}>Para qualquer tipo
                                de pagamento, em qualquer canal, em qualquer dispositivo, conte com a Embed.</span>
                            </p>
                        </div>
                        <div className='listImage flex flex-col md:flex-row items-start gap-6'>
                            <ul className="solutions-list flex-1">
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Gestão de recebíveis</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Motores de pagamento (TEF)</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pix e Wallets</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">XML Storage</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Smart POS Android HUB</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pagamento de contas</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conta digital</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Cupom digital e fidelização de clientes</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Data insights</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Mídia programática</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Empréstimos e investimentos</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conciliação bancária</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Recarga digital</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">E muito mais soluções embedadas!</li>
                            </ul>
                            <div className="solutions-image flex-1 flex justify-center md:justify-end">
                                <img src="home/person1.png" alt="Imagem descritiva" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about-dll py-[20px] px-4 md:px-[80px] text-[18px] md:text-[24px]" >
                <div className='text1'>
                    <p><span>Code once_</span></p>
                    <p>Uma vez Embed,</p>
                    <p>Embedado está.</p>
                </div>
                <div className='text2'>
                    <p>/um único idioma</p>
                    <p>/uma única integração</p>
                    <p>/um único protocolo</p>
                </div>
                <div className='text3'>
                    <p>/sem DLL Hell</p>
                    <p>/sem API Hell</p>
                    <p className='text-white'>_muito mais fácil.</p>
                </div>
            </section>

            {/* Seção Soluções */}
            <section className="solutions">
                <h2>Soluções em destaque</h2>
                <div className="solution-list">
                    <div className="solution-item">
                        <img src="home/tef.png" alt="Embed.TEF" className="solution-img" />
                        <div className="solution-desc">Embed como Hub de Pagamento</div>
                        <button className="solution-btn"><Link to={'/tef'} style={{ textDecoration: 'none', color: 'inherit' }}>Saiba mais ›</Link></button>
                    </div>
                    <div className="solution-item">
                        <img src="home/pos.png" alt="Embed.POS-Connect" className="solution-img" />
                        <div className="solution-desc">Smart POS Android Hub</div>
                        <button className="solution-btn"><Link to={'/pos'} style={{ textDecoration: 'none', color: 'inherit' }}>Saiba mais ›</Link></button>
                    </div>
                    <div className="solution-item">
                        <img src="home/xml.png" alt="Embed.XML" className="solution-img" />
                        <div className="solution-desc">Data Store Lake</div>
                        <button className="solution-btn"><Link to={'/xml'} style={{ textDecoration: 'none', color: 'inherit' }}>Saiba mais ›</Link></button>
                    </div>
                    <div className="solution-item">
                        <img src="home/pix.png" alt="Embed.PIX" className="solution-img" />
                        <div className="solution-desc">Integração Pix</div>
                        <button className="coming-soon">Em breve ⓘ</button>
                    </div>
                    <div className="solution-item">
                        <img src="home/payment.png" alt="Embed.Payments" className="solution-img" />
                        <div className="solution-desc">Gestão de Contas a Pagar</div>
                        <button className="coming-soon">Em breve ⓘ</button>
                    </div>
                    <div className="solution-item">
                        <img src="home/caas.png" alt="Embed.CaaS" className="solution-img" />
                        <div className="solution-desc">Community as a Service</div>
                        <button className="coming-soon">Em breve ⓘ</button>
                    </div>

                    <div className="section-divider flex-col items-center">
                        <img src="home/person2.png" alt="Divisão de seções" className="w-full" />  
                        <button className="rounded-md md:absolute md:top-[80%] md:left-[70%] md:-translate-x-1/2 md:-translate-y-1/2 md:mt-0 mt-6 px-5 py-3 bg-[#80FF80] text-black border-none cursor-pointer text-xs z-20 transition-colors duration-300 ease-in-out">
                            <Link
                                to="/community"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                Conheça a Comunidade ›
                            </Link>
                        </button>
                                          
                    </div>
                </div>
            </section>


            {/* Seção Empresas Parceiras */}
            <section className="partners">
                
                <p className='mb-4'>EMPRESAS PARCEIRAS</p>
                <h4>Quem evolui o ecossistema com a Embed</h4>
                <div className="partners-list">
                    <img src="home/amazon.png" alt="Amazon" />
                    <img src="home/mastercard.svg" alt="MasterCard" />
                    <img src="home/microsoft.png" alt="Microsoft" />
                    <img src="home/american.png" alt="Microsoft" />
                    <img src="home/visa.png" alt="Visa" />
                    <img src="home/hipercard.png" alt="Hipercard" />
                    <img src="home/google.png" alt="Google" />
                    <img src="home/vr.png" alt="VR Benefícios" />
                    <img src="home/elo.png" alt="Elo" />
                    <img src="home/get-net.png" alt="GetNet" />
                </div>
            </section>


            {/* Seção Depoimentos */}
            <section className="testimonials px-4">
                <p className="title">DEPOIMENTOS</p>
                <h4>Com a palavra, os membros da nossa comunidade</h4>
                <div className="testimonials-carousel">
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"A Embed revolucionou nossa forma de operar no mercado!"</p>
                            <p style={{color:'white', margin:0}}>Heloisa Lisboa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CEO da SWH Curitibana</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Uma experiência incrível com soluções eficazes!"</p>
                            <p style={{color:'white', margin:0}}>Fellipe Costa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Atma Sistemas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"O suporte da Embed é impecável, sempre atentos às nossas necessidades! Recomendo"</p>
                            <p style={{color:'white', margin:0}}>Carla Dias</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CTO da TecVendas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Facilitou muito nosso processo de integração com o PDV, altamente recomendada!"</p>
                            <p style={{color:'white', margin:0}}>Marcos Ferreira</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Solux Sistemas</p>
                        </blockquote>
                    </div>
                </div>
                <div className="carousel-controls">
                    <button className="prev-btn">‹</button>
                    <button className="next-btn">›</button>
                </div>
            </section>
            <section className="options-section">
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        comunidade que </span>está
                        mudando o varejo!</p>
                    <p>Preencha o formulário clicando abaixo
                        e entraremos em contato com você
                        em breve para iniciar os trabalhos.</p>
                    <button className='rounded-md'><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato </span>
                        com a Embed pelo
                        nosso WhatsApp:</p>
                    <p>Nosso time está à disposição no
                        WhatsApp para tirar dúvidas e apoiar você
                        no preenchimento dos primeiros dados.</p>
                    <button className='rounded-md' onClick={() => window.open('https://wa.me/5511987266696', '_blank')}>Fale conosco</button>
                </div>

            </section>
        </div>
    );
};

export default Home;
