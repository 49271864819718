import React, { useState } from 'react';
import '../styles/About.css'; // Importe o CSS para estilizar a página
import { Link } from 'react-router-dom';
const About = () => {
    const [startIndex, setStartIndex] = useState(0); // Estado para controlar o índice inicial
    const totalItems = 9; // Total de imagens
    const itemsPerPage = 5; // Quantidade de itens visíveis por vez

    const handlePrevClick = () => {
        setStartIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0)); // Atualiza o índice inicial para retroceder
        console.log('Prev click');
    };

    const handleNextClick = () => {
        setStartIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, totalItems - itemsPerPage)); // Avança o índice
        console.log('Next click');
    };

    const languages = [
        { src: "/about/c++.png", alt: "c++" },
        { src: "/about/delphi.png", alt: "delphi" },
        { src: "/about/python.png", alt: "python" },
        { src: "/about/java.png", alt: "java" },
        { src: "/about/php.png", alt: "php" },
        { src: "/about/csharp.png", alt: "c#" },
        { src: "/about/go.png", alt: "go" },
        { src: "/about/dotnet.png", alt: ".NET" },
        { src: "/about/flutter.png", alt: "flutter" },
    ];

    return (
        <div className="embed">
            <section className="header">
                <img className="background-img" src="/about/backgroundAbout.jpg" alt="Imagem descritiva" />
                <div className="header-text-1">
                    <div className="header-column">
                        <h2 className='text-[40px] md:text-[50px]'>
                            Bem-vindo ao<br/>
                            <span className="highlight" >futuro da tecnologia</span><br/>
                            no varejo!
                        </h2>
                    </div>
                    <div className="header-column">
                        <p className='text-[20px] md:text-[25px]'>
                            Somos um grupo de inquietos por tecnologia e
                            inovação, focados em levar as melhores
                            soluções para o varejo no Brasil e oferecemos a
                            estrutura de tecnologia que apoia e valoriza o
                            sucesso duradouro do seu negócio!
                        </p>
                    </div>
                </div>
            </section>

            {/* Seção Sobre a Embed */}
            <section className="aboutEmbed">
                <div className="aboutEmbed-content">
                    <div className="">
                        <img src="/community-green-icon.png" alt="Sobre a Embed" className="icon-image h-12" />
                        <p className='text-justify'>Contamos com <span>mais de 20 anos de experiência
                            em Automação Comercial. </span>
                            Com pessoas,ferramentas e tecnologia, embarcamos serviços
                            financeiros de modo massificado em soluções
                            de PDV.</p>
                        <img src="/about/person3.png" alt="Sobre a Embed" className="person-image1" />

                    </div>
                    <div className="about-content">
                        <img src="/about/person4.png" alt="Sobre a Embed" className="person-image" />
                        <img src="/hand-icon.png" alt="Sobre a Embed" className="icon-image h-12 mt-4 hidden md:block"  />
                        <p className='text-justify'>Não somos apenas uma plataforma, somos uma
                            comunidade de profissionais apaixonados por
                            tecnologia e inovação. Nosso pilar é a confiança
                            em nosso ecossistema e em nossos parceiros.
                            <span> Juntos, vamos transformar a maneira como seu
                                negócio opera e cresce!</span></p>
                        <button className='rounded-md'><Link to={'/community'}>Quero ser Embed!</Link></button>
                    </div>
                </div>
            </section>

            <section className="about-tecnologies mt-6">
                <p className='font-light mt-4'>O JEITO EMBED</p>
                <h2 className='font-medium text-[30px] mt-4 px-4 md:px-0'>Nossa forma de fazer tecnologia</h2>
                <div className='about-tecnologies-info mt-8 mb-8'>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon1.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[120px]'>Mind-set de
                            integração e API</h5>
                    </div>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon2.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[160px]'>Se não for para inovar, nem saimos de casa!</h5>
                    </div>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon3.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[150px]'>Varejo está
                            em nosso sangue!</h5>
                    </div>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon4.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[150px]'>Apaixonados por
                            desenvolvedores!</h5>
                    </div>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon5.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[200px]'>Time de profissionais
                            e especialistas disponível</h5>
                    </div>
                    <div className='tec-image'>
                        <img src="/about/about-tec-icon6.png" alt="Sobre a Embed" className="tec-image" />
                        <h5 className='font-semibold max-w-[150px]'>Somos loucos por
                            pontos de venda!</h5>
                    </div>
                </div>
            </section>

            {/* Seção linguagens */}
            <section className="languages mt-6">
                <p>LINGUAGEM E SISTEMA OPERACIONAL AGNÓSTICO</p>
                <h2 className='mt-4 text-[20px]  md:text-[30px] '><span className='font-bold '>Integração completa e suporte</span> para simplificar a<br/>
                    vida dos programadores e da comunidade</h2>
                <div className="language-list-wrapper mt-16">
                    <div className="language-item" style={{ transform: `translateX(-${startIndex * 120}px)` }}>
                        {languages.map((lang, index) => (
                            <img key={index} src={lang.src} alt={lang.alt} className="language-img" />
                        ))}
                    </div>
                        <img src="/left.png" alt="left" className="left-arrow" onClick={handlePrevClick} />
                        <img src="/right.png" alt="right" className="right-arrow" onClick={handleNextClick} />
                </div>
                <h2 className='mt-24 text-[20px]  md:text-[30px] '><span className='font-bold '>Não importa o sistema operacional,</span><br/>
                    a Embed tem a solução</h2>
                <div className="so-item mb-24">
                    <img src="/about/android.png" alt="android" className="so-img" />
                    <img src="/about/windows.png" alt="windows" className="so-img" />
                    <img src="/about/linux.png" alt="linux" className="so-img" />
                    <img src="/about/ios.png" alt="ios" className="so-img" />
                    <img src="/about/internet.png" alt="internet" className="so-img" />
                </div>
            </section>

            {/* Seção Depoimentos */}
            <section className="makeEmbed">
                <div className='flex flex-col w-full justify-center items-center px-20 space-y-6'>
                <img src="/community-green-icon.png" alt="Sobre a Embed" className="h-auto w-28" />
                <h4>Fazer parte da nossa comunidade leva apenas alguns<br/>
                    minutos, <span className='font-bold'>preencha o formulário clicando abaixo:</span></h4>
                <button className='rounded-md w-52'><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>
            </section>

            {/* Seção Empresas Parceiras */}
            <section className="members">
                <p className='text-white mt-8' style={{letterSpacing:'2px'}}>RESPONSABILIDADE TECNICA</p>
                <h4 className='mt-8'>Os especialistas da Embed</h4>
                <div className="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-[100px]">
                    <div className='text-center p-[20px] w-[350px]'>
                        <img src="/about/jf.png" alt="member" className='' />
                        <p className='text-[32px] text-[#80FF80]'>Jonathan Feder</p>
                        <p className='font-semibold text-white'>Diretor de Negócios</p>
                        <p className='text-white mt-6'>"Responsável por impulsionar estratégias comerciais, identificar oportunidades de mercado e liderar equipes para entregar soluções inovadoras que atendam às necessidades do mercado."</p>
                    </div>
                    <div className='text-center p-[20px] w-[350px]'>
                        <img src="/about/ca.png" alt="member" />
                        <p className='text-[32px] text-[#80FF80]'>Claudenir Andrade</p>
                        <p className='font-semibold text-white'>Diretor de Tecnologia</p>
                        <p className='text-white mt-6'>"Conduz a visão tecnológica da empresa, liderando o desenvolvimento de soluções robustas e alinhando a inovação com as demandas dos clientes em diferentes setores."</p>
                    </div>
                </div>
                <div className="relative flex flex-col items-center">
                    <img src="/home/person2.png" alt="Divisão de seções" className="w-full md:w-[1100px]" />
                        <button className="rounded-md md:absolute md:top-[80%] md:left-[65%] md:-translate-x-1/2 md:-translate-y-1/2 md:mt-0 mt-6 px-5 py-3 bg-[#80FF80] text-black border-none cursor-pointer text-xs z-20 transition-colors duration-300 ease-in-out">
                            <Link
                                to="/community"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                Conheça a Comunidade ›
                            </Link>
                        </button>
                </div>   
            </section>

            {/* Nova Seção: Nosso Impacto */}
            {/* Nova Seção com Opções de Contato e Comunidade */}
            <section className="options-section">
   
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        nossa comunidade</span></p>
                    <p className='desc'>Conecte-se com outros desenvolvedores,
                        compartilhe ideias e cresça junto
                        com a gente!</p>
                    <button className="rounded-md"><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato
                        conosco</span></p>
                    <p className='desc'>Tem alguma dúvida ou precisa de suporte?
                        Nossa equipe está pronta para ajudar.</p>
                    <button className="rounded-md" onClick={() => window.open('https://wa.me/5511987266696', '_blank')}>Fale Conosco</button>
                </div>
            </section>

        </div>
    );
};

export default About;
