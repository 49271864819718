import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="navbar h-20 px-4 md:px-12">
            <img src="/logo.png" alt="Logo" className="w-auto h-[30px] hidden md:block" />
            <ul className="flex flex-row space-x-4 md:space-x-6 text-white text-[16px] w-full md:w-auto">
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about">A Embed</NavLink></li>
                <li><NavLink to="/community">Community</NavLink></li>
                <li 
                    className="dropdown" 
                    onMouseEnter={() => setShowDropdown(true)} 
                    ref={dropdownRef}
                >
                    <span className='cursor-pointer hover:text-[#66cc66]' onClick={toggleDropdown}>Soluções</span>
                    <ul className={`dropdown-menu  ${showDropdown ? 'show' : ''}`}>
                        <li className='px-1.5 py-0.5'><NavLink to="/tef">TEF</NavLink></li>
                        <li className='px-1.5 py-0.5'><NavLink to="/xml">XML</NavLink></li>
                        <li className='px-1.5 py-0.5'><NavLink to="/pos">POS</NavLink></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
