import React from 'react';
import { FaInstagram, FaLinkedin, FaWhatsapp, FaDiscord, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className="footer">
            <section className="contact">
                <div className="contact-info">
                    <div className='col1'>
                        <div className="contact-item">
                            <img src="/logo.png" alt="Logo" />
                            <p style={{lineHeight:'2.0', fontSize:'18px', marginTop:'40px'}}>
                                Reinventamos a forma como software houses, automações comerciais e comerciantes
                                operam e crescem seus negócios com tecnologia.
                            </p>
                        </div>
                    </div>
                    <div className='col1'>
                        <div className="contact-item">
                            <h3>Acesse as páginas</h3>
                            <ul className="">
                                <li><Link to={'/'}>Home</Link></li> 
                                <li><Link to={'/about'}>A Embed</Link></li>
                                <li><Link to={'/community'}>Community</Link></li>
                            </ul>
                        </div>
                        <div className="contact-item">
                            <h3>Soluções</h3>
                            <ul className="">
                                <li><Link to={'/tef'}>Embed.TEF</Link></li>
                                <li><Link to={'/pos'}>Embed.POS-Connect</Link></li>
                                <li><Link to={'/xml'}>Embed.XML</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='col1'>
                        <div className="contact-item">
                            <h3>Atendimento</h3>
                            <p>segunda a sexta 9h às 18h</p>
                        </div>

                        <div className="contact-item">
                            <h3>Email</h3>
                            <p>contato@embed.it</p>
                        </div>
                        <div className="contact-item">
                            <h3>Fone</h3>
                            <p>+55 11 98726-6696</p>
                        </div>
                    </div>


                </div>
                <div className="social-media">
                    <ul>
                        <li><FaInstagram size={30} color='#000000' className='icon98'/></li>
                        <li><FaLinkedin size={30} color='#000000' className='icon98' /></li>
                        <li><FaWhatsapp size={30} color='#000000' className='icon98'/></li>
                        <li><FaDiscord size={30} color='#000000' className='icon98'/></li>
                        <li><FaGithub size={30} color='#000000' className='icon98'/></li>
                    </ul>
                </div>

            </section>
            <div className='copyright px-4 md:px-8'>
                <p>&copy; 2024 www.embed.it | Todos os direitos reservados.</p>
                <p className='hidden md:block'>ATMA</p>
            </div>
        </div>
    );
};

export default Footer;
