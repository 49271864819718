import React from 'react';
import '../styles/Pos.css'; // Importe o CSS para estilizar a página
import '../styles/Tef.css'; 
import { Link } from 'react-router-dom';

const Pos = () => {

    return (
        <div className="embedPos">
            <section className="headerTef">
                <img className="background-img" src="/pos/backgroundPos.jpg" alt="Imagem descritiva" />
                <div className='flex w-full h-full justify-center items-center'>
                    <div className="flex flex-col md:flex-row w-full md:max-w-[1150px] md:w-[80%] h-full justify-center items-center px-4 md:px-0 gap-x-0 md:gap-x-[40px] gap-y-[80px] md:gap-y-0">
                        <div className="md:flex-1 flex flex-col justify-center text-start">
                            <p className='text-[35px] md:text-[45px] text-white'>
                                A Embed te conecta <br/> <span className="highlight">aos principais POS</span>
                            </p>
                        </div>
                        <div className="flex w-full md:w-[450px] h-auto px-4 md:px-0">
                            <img src="/pos/pos.png" alt="Imagem descritiva" className='w-full h-full' />
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-tef">
                <div className="flex flex-col px-4 md:px-0 mb-10">
                    <div className="tef-text">
                        <div className="flex flex-col md:flex-row justify-center items-center">
                            <div className='flex h-auto w-full md:w-[450px]'>
                                <img src="/pos/person7.png" alt="Imagem descritiva" className='flex w-full h-full' />
                            </div>
                            <div className='ml-0 mt-4 md:mt-0 md:ml-4'>
                                <p><span>Somos vidrados em facilitar a vida!</span> Criamos um
                                    idioma único de conexão com todos os POS de
                                    mercado, são 30 POS certificados e
                                    homologados com nossas solução Windows,
                                    Linux e Android para você comandar.
                                </p>
                                <p className='mt-4 text-bold text-[#80FF80]'><span>
                                    Tudo sempre “Embedado” e
                                    integrado no seu sistema.</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex-col justify-center items-center mt-[40px]">
                            <div className='whitespace-nowrap md:whitespace-normal'>
                                <h2 className='font-normal text-[25px]'>Quer embedar essa solução?</h2>

                            </div>
                            <div className="tef-title-row flex-col md:flex-row">
                                <h2 className='font-bold text-[25px]'>Clique e fale conosco!</h2>
                                <hr className='hidden md:block'/>
                                <button className='about-btn rounded-md mt-4 md:mt-0' onClick={() => window.open('https://wa.me/5511987266696', '_blank')}>Fale conosco</button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about-dll py-[20px] px-4 md:px-[80px] text-[18px] md:text-[24px]" >
                <div className='text1'>
                    <p><span>Code once_</span></p>
                    <p>Uma vez Embed,</p>
                    <p>Embedado está.</p>
                </div>
                <div className='text2'>
                    <p>/um único idioma</p>
                    <p>/uma única integração</p>
                    <p>/um único protocolo</p>
                </div>
                <div className='text3'>
                    <p>/sem DLL Hell</p>
                    <p>/sem API Hell</p>
                    <p className='text-white'>_muito mais fácil.</p>
                </div>
            </section>

            <section className="fluxosPos px-4 py-[20px] md:px-[40px]">
                <div className="fluxos-list mt-16">
                    <div className='fluxo-item-pos'>
                        <img src="/pos/pos-fluxo.png" alt="fluxo do pos" />
                    </div>

                </div>
                <div className="relative flex flex-col items-center mt-16 mb-16">
                    <img src="/home/person2.png" alt="Divisão de seções" className="w-full md:w-[1100px]" />
                        <button className="rounded-md md:absolute md:top-[80%] md:left-[65%] md:-translate-x-1/2 md:-translate-y-1/2 md:mt-0 mt-6 px-5 py-3 bg-[#80FF80] text-black border-none cursor-pointer text-xs z-20 transition-colors duration-300 ease-in-out">
                            <Link
                                to="/community"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                Conheça a Comunidade ›
                            </Link>
                        </button>
                </div>
            </section>


            {/* Nova Seção com Opções de Contato e Comunidade */}
            <section className="options-section">
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        comunidade que </span>está
                        mudando o varejo!</p>
                    <p>Preencha o formulário clicando abaixo
                        e entraremos em contato com você
                        em breve para iniciar os trabalhos.</p>
                    <button className='rounded-md'><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato </span>
                        com a Embed pelo
                        nosso WhatsApp:</p>
                    <p>Nosso time está à disposição no
                        WhatsApp para tirar dúvidas e apoiar você
                        no preenchimento dos primeiros dados.</p>
                    <button className='rounded-md' onClick={() => window.open('https://wa.me/5511987266696', '_blank')}>Fale conosco</button>
                </div>

            </section>
        </div>
    );
};

export default Pos;
