import React from 'react';
import '../styles/Community.css'; // Importe o CSS para estilizar a página
import { Link} from 'react-router-dom';

const Community = () => {
    return (
        <div className='community'>

            <section className="headerCommunity">
                <div className="flex w-full h-full justify-center items-center">
                    <div className='w-[90%] md:w-[60%]'>
                        <img src="/community/embedCommunity.png" alt="EmbedCommunity" className='w-full'/>
                    </div>
                    
                </div>
            </section>


            <section className='aboutEmbed flex justify-center items-center '>
                <div className="flex w-full px-4 md:px-0 md:w-[1200px] grid grid-cols-1 md:grid-cols-2 gap-x-8  mt-16">
                        <div className='flex w-full justify-center items-center '>
                            <div className='text-[20px] text-start' style={{lineHeight:'1.8'}}>
                            <p>Comunidade para desenvolvedores
                                de automação comercial, com uma
                                nova perspectiva, muita tecnologia e
                                inovação para o setor.</p>
                                <br/>
                            <p>Nosso compromisso é <span className='font-bold'>desenvolver e
                                empoderar o ecossistema </span>através de
                                eventos, grupos de estudo, webinars,
                                cursos e também viagens
                                internacionais.</p>
                            </div>
                            
                        </div>
                        <div className='flex mt-8'>
                        <img src="/community/personCommunity.png" alt="Sobre a Embed" className="flex w-full h-auto" />
                        </div>
                    
                </div>
            </section>

            <section className="about-community">
                <p>TUDO QUE VOCÊ PRECISA</p>
                <h2>Benefícios da Comunidade</h2>
                <div className='about-community-info'>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon1.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Profissionais de prontidão</h5>
                            <h6>Apoio de um time de especialistas</h6>
                        </div>
                    </div>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon2.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Fórum da comunidade</h5>
                            <h6>Grupos no Discord com moderação de desenvolvedores Embed</h6>
                        </div>
                    </div>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon3.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Jornada de desenvolvedores</h5>
                            <h6>Apoio para homologação de sistema</h6>
                        </div>
                    </div>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon4.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Parceiros no ecossistema</h5>
                            <h6>Mais de 1.200 parceiros</h6>
                        </div>
                    </div>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon5.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Eventos de destaque</h5>
                            <h6>Tech days, webinares, lives, viagens com muita inovação</h6>
                        </div>
                    </div>
                    <div className='benefic-image'>
                        <img src="/community/about-community-icon6.png" alt="Sobre a Embed" className="benefic-image" />
                        <div className="benefic-text">
                            <h5>Proximidade</h5>
                            <h6>Através de diversos canais e grupos para os parceiros</h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className="makeEmbed">
                <div className='flex flex-col w-full justify-center items-center px-20 space-y-6'>
                <img src="/community-green-icon.png" alt="Sobre a Embed" className="h-auto w-28" />
                <h4>Fazer parte da nossa comunidade leva apenas alguns<br/>
                    minutos, <span className='font-bold'>preencha o formulário clicando abaixo:</span></h4>
                <button className='rounded-md w-52'><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>

                <div className="relative flex flex-col items-center mt-6">
                    <img src="/community/embed-community-caas.png" alt="Divisão de seções" className="w-full md:w-[1100px]" />
                    <button className="w-52 h-12 rounded-md md:absolute md:top-[80%] md:left-[20%] md:mt-0 mt-6 px-5 py-3 bg-[#80FF80] text-black border-none cursor-pointer text-xs z-20 transition-colors duration-300 ease-in-out">
                        <Link
                            to="/community"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Conheça a Comunidade ›
                        </Link>
                    </button>
                </div>
            </section>


            <section className="options-section">
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        comunidade que </span>está
                        mudando o varejo!</p>
                    <p>Preencha o formulário clicando abaixo
                        e entraremos em contato com você
                        em breve para iniciar os trabalhos.</p>
                    <button className="rounded-md"><Link to={'/community'}>Quero ser Embed!</Link></button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato </span>
                        com a Embed pelo
                        nosso WhatsApp:</p>
                    <p>Nosso time está à disposição no
                        WhatsApp para tirar dúvidas e apoiar você
                        no preenchimento dos primeiros dados.</p>
                    <button  className="rounded-md" onClick={() => window.open('https://wa.me/5511987266696', '_blank')}>Fale conosco</button>
                </div>
            </section>


        </div>
    );
};

export default Community;
