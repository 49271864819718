import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Community from './pages/Community';
import Tef from './pages/Tef';
import Xml from './pages/Xml';
import Pos from './pages/Pos'

const App = () => {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/community" element={<Community />} />
                        <Route path="/tef" element={<Tef />} />
                        <Route path="/xml" element={<Xml />} />
                        <Route path="/pos" element={<Pos />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
